@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primaryColor: #146d58;
  --secondaryColor: #fff6e9;
  --errorColor: #ff0033;
  --successColor: #00cc66;
  --infoColor: #0066ff;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}

input, textarea, button {
  font-family: "Inter", sans-serif;
}

#wrapper {
  max-width: 1600px;
  margin: 18vh auto 0;
  padding: 0 7rem;
  min-height: 45vh;
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: black;
  text-decoration: none;
}

.alertMessageContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.alertMessageContainer p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  padding-left: 0.5rem;
  width: 100%;
}

.alertMessageContainer svg {
  width: 1.5rem;
  height: auto;
}

.alertMessageContainer.error {
  border: solid 1px var(--errorColor);
}

.alertMessageContainer.error p {
  color: var(--errorColor);
}

.alertMessageContainer.success {
  border: solid 1px var(--successColor);
}

.alertMessageContainer.success p {
  color: var(--successColor);
}

.alertMessageContainer.info {
  border: solid 1px var(--infoColor);
}

.alertMessageContainer.info p {
  color: var(--infoColor);
}

.btnPrimary {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 12px;
  color: white;
  background-color: var(--primaryColor);
  border: solid 2px var(--primaryColor);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.btnPrimary.outline {
  background-color: transparent;
  color: var(--primaryColor);
  border-color: var(--primaryColor);
}

.btnPrimary.full {
  width: 100%;
}

.btnPrimary.disabled {
  background-color: #d9d9d9;
  border: solid 2px #d9d9d9;
  cursor: not-allowed;
}

.btnPrimary:hover {
  opacity: 0.8;
}

.btnPrimary.outline:hover {
  background-color: var(--primaryColor);
  color: white;
  opacity: 1;
}

.buttonSpinner {
  -ms-animation: btnSpinner 1s infinite linear;
  -moz-animation: btnSpinner 1s infinite linear;
  -webkit-animation: btnSpinner 1s infinite linear;
  -o-animation: btnSpinner 1s infinite linear;
  animation: btnSpinner 1s infinite linear;
}

.link,
.linkAlt {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--primaryColor);
  background-color: transparent;
  border: none;
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}

.link:hover,
.linkAlt:hover {
  opacity: 0.7;
}

@keyframes btnSpinner {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#loadingText {
  color: #6e6e6e;
  animation: fadeIn 1s ease-in-out infinite alternate;
  -webkit-animation: fadeIn 1s ease-in-out infinite alternate;
  -moz-animation: fadeIn 1s ease-in-out infinite alternate;
  -ms-animation: fadeIn 1s ease-in-out infinite alternate;
  -o-animation: fadeIn 1s ease-in-out infinite alternate;
}

.pageHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.pageHeaderContainer .pageHeader h1 {
  font-size: 2rem;
  font-weight: 700;
  color: black;
  margin-bottom: 1rem;
}

.pageHeaderContainer .pageHeader p {
  font-size: 1rem;
  font-weight: 400;
  color: #858585;
}

.pageHeaderContainer .legalContent p {
  margin-bottom: 1rem;
  line-height: 1.5rem;
}

.pageHeaderContainer .legalContent ul {
  margin-left: 1.5rem;
}

.pageHeaderContainer .legalContent a {
  color: var(--primaryColor);
  text-decoration: none;
  font-weight: bold;
}

.pageHeaderContainer .legalContent a:hover {
  text-decoration: underline;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 992px) {
  #wrapper {
    max-width: 100%;
    padding: 0 5rem;
  }
}

@media screen and (max-width: 768px) {
  #wrapper {
    padding: 0 3rem;
  }
}

@media screen and (max-width: 576px) {
  #wrapper {
    padding: 0 1.5rem;
  }
}

.alimonyCalculator .result {
  padding-left: 1.25rem;
  padding-block: 0.75rem;
  margin-bottom: 2rem;
  border-left: solid 6px var(--primaryColor);
  /* add background color of primaryColor but lower opacity */
  background-color: rgba(20, 109, 88, 0.1);
}

.alimonyCalculator .result h2 {
  font-size: 2.5rem;
}
