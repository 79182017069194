.formRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.formRow .formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.formGroup.half {
  width: 50%;
  padding-right: 0.5rem;
}

.formGroup label {
  font-size: 0.875rem;
  font-weight: 500;
  color: black;
}

.formGroup input {
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: black;
  padding: 0.5rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  transition: border-color 0.3s ease-in-out;
  -webkit-transition: border-color ease-in-out 0.3s;
  -moz-transition: border-color ease-in-out 0.3s;
  -ms-transition: border-color ease-in-out 0.3s;
  -o-transition: border-color ease-in-out 0.3s;
}

.formGroup .passwordField input {
  width: 100%;
  padding-right: 3rem;
}

.formGroup .passwordField svg {
  position: absolute;
  margin-left: -2.5rem;
  margin-top: 6px;
  width: 1.5rem;
  height: auto;
  cursor: pointer;
}

.formGroup input:focus,
.formGroup textarea:focus {
  outline: none;
  border-color: var(--infoColor);
}

.formGroup input.error,
.formGroup textarea.error {
  border-color: var(--errorColor);
}

.formGroup .errorMsg {
  color: var(--errorColor);
}

@media (max-width: 992px) {
  .formRow {
    flex-direction: column;
  }
  .formGroup.half {
    width: 100%;
    padding-right: 0;
  }
}
