.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
}

.navContainer {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 7rem;
  box-shadow: 0px 4px 28px -2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.logo img {
  width: auto;
  height: 3.5rem;
}

.navLinksContainer {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navLink {
  font-size: 1rem;
  font-weight: 400;
  color: black;
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.navLink.active {
  color: var(--primaryColor);
  font-weight: 600;
}

.navLink:hover {
  opacity: 0.7;
}

.navbar.transparent .navContainer {
  background-color: transparent;
  padding: 1rem 7rem;
  box-shadow: none;
}

.navbar.transparent .navContainer .navLink {
  color: white;
}

@media screen and (max-width: 992px) {
  .navContainer {
    padding: 1rem 3rem;
  }
}

@media screen and (max-width: 768px) {
  .navContainer {
    padding: 1rem 2rem;
  }
  .navLinksContainer {
    gap: 1.5rem;
  }
  .logo img {
    height: 2.5rem;
  }
}

@media screen and (max-width: 576px) {
  .navContainer {
    padding: 1rem 1rem;
  }
  .navLinksContainer {
    display: none;
  }
}
