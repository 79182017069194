.featuredPostsContainer {
  padding: 0 7rem 7rem;
}

.featuredPostsContainer h1 {
  font-family: "Noto Serif", serif;
  font-size: 2.5rem;
  color:  var(--primaryColor);;
  text-align: center;
  margin-bottom: 1.5rem;
}

.container-post {
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding: 2rem 0 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.column-post {
  width: 100%;
}

.column-post img {
  width: 100%;
}

.button-post {
  background-color: #787979;
  color:  var(--secondaryColor);;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #626568;
}

.description-post {
  margin-top: 10px;
  font-size: 16px;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
}

.title-post {
  font-size: 1.25rem;
  font-weight: 600;
}

.button-section {
  margin-top: 30px;
  background-color:  var(--primaryColor);
  color: var(--secondaryColor);
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  border: none;
  font-size: 1.2em;
  border-radius: 5px;
}

