.hero-section {
  background-image: url("../../img/heroSection.png");
  background-size: cover;
  background-position: center bottom;
  text-align: left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 7rem;
}
.hero-section h1 {
  font-family: "Noto Serif", serif;
  font-size: 3.5rem;
  margin-bottom: 1rem;
  color: var(--secondaryColor);
}

.hero-section p {
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--secondaryColor);
  margin-bottom: 1.5rem;
}

.hero-section button {
  font-size: 1.25rem;
}
