.auth-container {
  display: flex;
  height: 100vh;
}

.auth-container .auth-illustration {
  width: 50%;
  background-color: var(--primaryColor);
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.05) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 25px 25px;
  padding: 3rem;
  color: #fff;
}

.auth-container .auth-illustration .logo {
  color: #fff;
}

.auth-container .auth-illustration .auth-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.auth-container .auth-illustration h1 {
  font-size: 3rem;
  font-weight: 500;
}

.auth-container .auth-illustration p {
  font-weight: 300;
  line-height: 1.5rem;
}

.auth-container .auth-illustration .auth-info-head > p {
  margin-top: 2rem;
}

.auth-container .auth-illustration .review {
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  border-radius: 1.5rem;
}

.auth-container .auth-illustration .review .reviewer {
  display: flex;
  margin-top: 0.75rem;
}

.auth-container .auth-illustration .review .avatar {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  padding: 4px;
  margin-right: 0.5rem;
}

.auth-container .auth-illustration .review .avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* vertical align to the avatar container */
  vertical-align: middle;
}

.auth-container .auth-illustration .review .name {
  font-weight: 500;
}

.auth-container .auth-form {
  width: 50%;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auth-container .auth-form h1 {
  margin: 4rem 0 2rem;
  font-weight: 400;
  font-size: 2.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
select {
  width: 100%;
  padding: 0.75rem;
  font-size: 16px;
  border: 1px solid #eee;
  border-radius: 0.5rem;
}

.auth-container .auth-form form p {
  text-align: center;
  margin-top: 20px;
}

.auth-container .auth-form form p a {
  color: var(--primaryColor);
  text-decoration: none;
  font-weight: bold;
}

.auth-container .auth-form form p a:hover {
  text-decoration: underline;
}

.auth-container .terms {
  text-align: left !important;
  color: #777;
}

@media screen and (max-width: 768px) {
  .auth-container .auth-illustration {
    display: none;
  }

  .auth-container .auth-form {
    width: 100%;
    padding: 2rem;
  }
}
