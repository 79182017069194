.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-paragraph-section {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.corner-top-right {
  position: absolute;
  right: 0;
  margin-top: -4rem;
}

.corner-bottom-left {
  position: absolute;
  left: 0;
  margin-top: 6rem;
}
.content {
  margin: 100px auto;
  max-width: 600px;
  padding: 20px 0px;
}
.content h2 {
  font-family: "Noto Serif", serif;
  font-size: 2rem;
  font-weight: 700;
  color: var(--primaryColor);
  margin-bottom: 1.5rem;
  
}
.content p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
